.dtzSettings{
    margin-top: 10px;
}

.dtzSettingsHintergrund{
    font-size: 20px;
    margin-left: 18px;
    top: 10px;
}

.dtzSettingsHintergrundDeckkraftSlider{
    margin-top: -24px;
    float:right;
    width: 190px;
}

.dtzSettingsHintergrundDeckkraftText{
    margin-left: 19px;
    margin-top: 15px;
    font-size: 14px;
}

.dtzSettingsHintergrundAnzeigenText{
    margin-left: 19px;
    margin-top: 15px;
    font-size: 14px;
}

.dtzSettingsHintergrundAnzeigenSwitch{
    float: right;
    margin-right: 10px;
    margin-top: -18px;
}

.dtzSettingsHintergrundSaveButton{
    margin-top:15px;
    float: right;
    width: 100%;
}