.dtzMesspunktItem {
  float: left;
  height: 24px;
  width: 24px;
  background: transparent;
}
.dtzMesspunktItemHeader{
  float: left;
  color: rgba(55,55,55,1);
  font-size: 20px;
  z-index: 10;
  max-height: 20px;
}

  
.dtzMesspunktItemHeading{
  color: rgba(55,55,55,1);
  font-size: 20px;
  padding-left: 5px;
  font-weight: 300;
}

.dtzMesspunktStripe{
  color: rgba(55,55,55,0.6);
  font-size: 12px;
  height: 48px;
  width: 110px;
  margin-left: 30px;
  margin-top: 10px;
  display: table-cell;
  float: left;
}

.dtzMesspunktStripeZeit{
  color: rgba(55,55,55,0.6);
  font-size: 12px;
  height: 48px;
  width: 110px;
  margin-left: 150px;
  margin-top: 5px;
  display: table-cell;
  float: left;
}

.dtzMesspunktItemFuellstandHeader{
  color: #a8a8a8;
  font-size: 12px;
  margin-left: 17px;
  display: table;
}

.dtzMesspunktItemFuellstandHeaderZeit{
  color: #a8a8a8;
  font-size: 12px;
  margin-left: 47px;
  display: table;
}

.dtzMesspunktStripePrognose{
  color: rgba(55,55,55,.6);
  font-size: 12px;
  height: 48px;
  width: 110px;
  margin-left: 160px;
  margin-top: 10px;
  display: table-cell;
  float: left;
}

.dtzMesspunktItemFuellstandValue{
  color: rgba(55,55,55,1);
  font-size: 20px;
  margin: 3px 0 0;
    margin-top: 3px;
    margin-right: 0px;
  margin-right: 40px;
  float:right;
  font-weight: 300;
}

.dtzMesspunktItemGauge{
  width: 40%;
  height: 68px;
  margin-left: -240px;
  margin-top: -18px;
  margin-bottom: 5px;
  float: left;
}

.dtzMesspunktChart{
  width: 210px;
  height: 80px;
  margin-left: -200px;
  margin-top: -25px;
  float: left;
}

.dtzMesspunktAktualisieren{
  float: right;
  margin-right: 50px;
}

.dtzMesspunktBackground{
  visibility: visible;
  position: fixed;
  margin-top: -100vh;
  z-index: -10;
  margin-top: -100vh;
}

.aktuellerWertAlarm{
	color: #AD1832;
}

.messpunktGrid{
  padding: 10px;
}