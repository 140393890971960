.dtzTitlebar{
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    height:45px;
    background-color: rgb(173, 24, 50);
    color: white;
    font-weight: 400;
    font-size: 21px;
}

.close {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.5;
    margin-top: -23px;
    margin-right: -25px;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 28px;
    width: 3px;
    background-color: rgb(255, 255, 255);
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
  