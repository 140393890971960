.dtzDetailAnsichtChart{
    width: 970px;
    height: 350px;
    margin-top: -180px;
    float: left;
    flex-direction: 'column';
    flex: 1;
    min-height: 0;
    min-width: 0;
}

.dtzDetailAnsichtBarChart{
    width: 970px;
    height: 350px;
    margin-top: 20px;
    display: flex;
    min-height: 0;
}

.dtzDetailAnsichtDataGrid{
    margin-top: -700px;
    width: 560px;
    height: 695px;
    float: right;
    min-height: 0;
}

.dtzDetailAnsichtMittelwert{
    margin-left: 455px;
}

.dtzDetailAnsichtRectangle{
    margin-left: 438px;
    margin-top: -15px;
    width: 14px;
    height: 12px;
    background: red none repeat scroll 0% 0%;
}

.dtzDetailAnsichtButtonAnzahlMesswerte{
    margin-top: -763px;
    float:right;
    margin-right: 10px;
}

.dtzDetailAnsichtButtonzeitraum{
    margin-top: -1580px;
    margin-left: 900px;
}

.dtzDetailEigenschaftBlock{
    float: left;
    margin-left: 20px;
    margin-top: -180px;
}

.dtzDetailEigenschaftItem{
    float:left;
    margin-top: 20px;
    width: 350px;
    height: 25px;
    border-bottom: 1px solid rgb(201, 191, 191);
 }

.dtzDetailEigenschaftHeader{
    float: left;
    font-size: 18px;
}

.dtzDetailEigenschaftValue{
    float:right;
    font-size: 21px;
}

.is-menu-separator-ct {
    padding: 0 0!important;
    line-height: 9px;
    color: #fff0;
  }