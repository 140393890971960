.dtzZeitraum{
    margin-top: -20px;
}

.dtzZeitraumButton{
    width: 100%;
    margin-top: 20px; 
    
 }

 .dtzZeitraumVon{
    height: 40px;
    margin-top: 10px;
    width: 100px;
}

 .dtzZeitraumBis{
     height: 40px;
     margin-top: 30px;
     width: 100px;
 }