.dtzInfoHeader{
    color: rgb(95, 95, 95);
    font-weight: 500;
    font-size: 20px;
}

.dtzInfoText{
    font-size: 14px;
}

.dtzInfo{
    min-height: 100%;
}

.dtzInfoImpressum{
    height: 760px;
    overflow-y: scroll;
}

.addressStyle{
    font-family: Roboto,RobotoFallback,"Noto Kufi Arabic",Helvetica,Arial,sans-serif;
    font-size: 15px !important;
}