.side-nav-outer-toolbar {
  flex-direction: column;
  display: flex;
  height: calc(100vh - 89px);
  width: 100%;
}

.layout-header {
  z-index: 1501;
}

.layout-body {
  flex: 1;
  min-height: 0;
}

.content {
  flex-grow: 1;
}

.verNum{
  position: absolute;
  margin-left: 0;
  color: gray;
  margin-top: 59px;
  margin-left: 89.8vw;
}