.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 19px;
}

.react-logo {
  width: 184px;
  height: 68px;
}

.plus {
  margin: 23px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;
    &.plus {
      margin: 0;
    }
  }
}

.ansicht-container{
  background-color: #f1f0f0;
  /* position: fixed; */
  /* left: 0; */
  /* top: 120px; */

  clear: both;
  margin-left: 5px;
  padding-right: 5px;
  border-right: 1px solid rgb(182, 182, 182);
}

.ansichtbadge{
  pointer-events: none;
  margin-left: 5px;
  height: 24px;
  width: 24px;
  float: right;
}

.ansichttext{
  font-size: 18px;
  color: #ff002b;
  float: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  padding-top: 0px;
  padding-bottom: 5px;
}

.badgecontainer{
  
}

.badgegreen {
  position:relative;
}


.badgegreen[data-badge]:after {
  content:attr(data-badge);
  position:absolute;
  top: -9px;
  right: 53px;
  font-size:.7em;
  background:green;
  color:white;
  width:18px;height:18px;
  text-align:center;
  line-height:18px;
  border-radius:50%;
  box-shadow:0 0 1px #333;
}

.badgegreenfront {
  position:relative;
}

.badgeorgangefront {
  position:relative;
}


.badgeorgangefront[data-badge]:after {
  content:attr(data-badge);
  position:absolute;
  top: -9px;
  left: 172px;
  font-size:.7em;
  background:#ffa500;
  color:white;
  width:18px;height:18px;
  text-align:center;
  line-height:18px;
  border-radius:50%;
  box-shadow:0 0 1px #333;
  font-weight: 700;
}

.badgeredfront {
  position:relative;
}
.badgeredfront[data-badge]:after {
  content:attr(data-badge);
  position:absolute;
  top: -9px;
  left: 203px;
  font-size:.7em;
  background:red;
  color:white;
  width:18px;height:18px;
  text-align:center;
  line-height:18px;
  border-radius:50%;
  box-shadow:0 0 1px #333;
  font-weight: 700;
}
