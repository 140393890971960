*, *::after, *::before {
	-webkit-user-select: none;
	-webkit-user-drag: none;
	-webkit-app-region: no-drag;
	cursor: default;
}

.loginForm{
	margin-left: -42vw;
	margin-top: 25vh;
}

.loginImage{
	background-image: url("../images/bg_hell.webp");
	background-size: cover;
	width: 100vw;
	height: 100vh;
	position: absolute;
	margin-left: -42vw;
	opacity: 0.5;
}

.loginHeader{
	height: 48px;
	background-color: #ad1832;
	margin-left: -42vw;
	width: 100vw;
	text-align: center;
	font-weight: normal;
	font-size: 28px;
    color: white;
	font-family: Roboto,RobotoFallback,"Noto Kufi Arabic",Helvetica,Arial,sans-serif;
	line-height: 1.2857;
}

.loginHeaderText{
	padding-top: 6px;
	padding-right: 11px;
}

